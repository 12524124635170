import $ from 'jquery';
import { gsap } from "gsap";
import TimelineAudio from './sound.component';
import 'slick-carousel';
import 'magnific-popup';

export default class Timeline {
    constructor() {
        this.element = document.querySelector('.js-timeline:not(.is-inited)');
        this.init();
    }

    init() {
        this.element.classList.add('is-inited');
        this._initCarousel();

        if(document.querySelectorAll('.js-timeline-audio').length) {
            const timelineAudioEl = new TimelineAudio();
        }

        window.addEventListener('resize', this._appHeight);
        this._appHeight();
    }

    _initCarousel() {
        let carousel = $(this.element).find('.js-timeline-carousel');

        const slickOptions = {adaptiveHeight: false,
            arrows: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            speed: 600,
            swipe: true,
            cssEase: 'linear',
            infinite: false,
            prevArrow: $('.timeline__arrow-left'),
            nextArrow: $('.timeline__arrow-right'),
            responsive: [
            {
                breakpoint: 1024,
                settings: "unslick"
            }
        ]};

        carousel.on('init', () => {
            this.initPopup();
        });
        carousel.slick(slickOptions)
            .on('breakpoint', () => { this.initPopup(); })
            .on('afterChange', function (e, slick, currentSlide, nextSlide) {
                history.pushState(null, null, '#' + $($(slick.$slides)[currentSlide]).find('.timeline__item')[0].id);
            });

        window.addEventListener('resize', () => {
            if($(window).width() >= 1024 && !$('.js-timeline-carousel').hasClass('slick-initialized')) {
                carousel.slick(slickOptions)
                    .on('breakpoint', () => { this.initPopup(); })
            }
        });

    }

    initPopup() {
        this.$lightboxTrigger = $('.js-timeline-lightbox-trigger');
        if(!this.$lightboxTrigger.length) return;
        this.$lightboxTrigger.magnificPopup({
            type: 'inline',
            preloader: true,
            fixedContentPos: true,
            removalDelay: 500,
            closeBtnInside: true,
            closeOnBgClick: true,
            enableEscapeKey: true,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = this.st.el.attr('data-effect');
                },
                afterClose: function() {
                    history.pushState(null, null, ' ');
                }
            },
            midClick: true
        });

        this.$lightboxTrigger.click((e) => {
            history.pushState(null, null, $(e.target).attr('href'));
        });

        if(window.location.hash && this.$lightboxTrigger.is('[href = "' + window.location.hash + '"]')) {
            this.$lightboxTrigger.filter('[href = "' + window.location.hash + '"]').filter('.timeline__popup-link').click();
        }
    }

    _appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

}
