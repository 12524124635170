export default class TimelineAudio {
    constructor() {
        this.element = document.querySelectorAll('.js-timeline-audio');
        this.init();
    }

    init() {
        this.element.forEach(function(item, index) {
            let audioControl = item.querySelector('.js-audio-control');
            let progressBar = item.querySelector('.js-progress');
            let playBtn = item.querySelector('.js-play');
            let pauseBtn = item.querySelector('.js-pause');

            playBtn.addEventListener('click', function() {
                audioControl.play();
                item.classList.add('is-playing');
            });

            pauseBtn.addEventListener('click', function() {
                audioControl.pause();
                item.classList.remove('is-playing');
            });

            audioControl.addEventListener('timeupdate', function() {
                progressBar.setAttribute("style", '--value:' + (100 * (this.currentTime / this.duration)));
                progressBar.setAttribute("aria-valuenow", (100 * this.currentTime / this.duration));
            });

            audioControl.addEventListener('ended', function() {
                item.classList.remove('is-playing');
            });
        });
    }
}
