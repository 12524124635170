import $ from 'jquery';
import 'slick-carousel';
import 'magnific-popup';
import {trackVideo} from 'general/js/videoTrackingService';

export default class CraftedCarousel {
    constructor() {
        this.$element = $('.js-crafted-carousel');
        this.$lightboxTrigger = $('.js-carousel-lightbox-trigger');
        this.init();
        this.vimeoPlayers = [];
    }

    init() {
        this.initCarousel();
        this.initVideoCursor();
        this.initPopup();
    }

    initCarousel() {
        this.$element.on('init', (event, slick) => {
            $($(slick.$slides)[0]).addClass('is-fade-in');
            if(window.location.hash && this.$element.find(window.location.hash).length) {
                slick.slickGoTo($(window.location.hash).parents('.slick-slide').data('slickIndex'));
            }
        });

        this.$element
            .slick({
                adaptiveHeight: false,
                arrows: true,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                speed: 600,
                swipe: true,
                cssEase: 'linear',
                infinite: true
            })
            .on('init', function(slick) {
                $(slick).find('.slick-next').on('click', () => {
                    if (window.ga !== undefined) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'siteengagement.click',
                            'siteengagementType': 'links clicked',
                            'siteengagementName': 'Next slide'
                        });
                    }
                });
                $(slick).find('.slick-prev').on('click', () => {
                    if (window.ga !== undefined) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'siteengagement.click',
                            'siteengagementType': 'links clicked',
                            'siteengagementName': 'Previous slide'
                        });
                    }
                });
            })
            .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                $(slick.$slides).removeClass('is-fade-in');
                $($(slick.$slides)[currentSlide]).addClass('is-fade-out');
            })
            .on('afterChange', function (e, slick, currentSlide, nextSlide) {
                $(slick.$slides).removeClass('is-fade-in');
                $(slick.$slides).removeClass('is-fade-out');
                $($(slick.$slides)[currentSlide]).addClass('is-fade-in');
                history.pushState(null, null, '#' + $($(slick.$slides)[currentSlide]).find('.crafted-carousel__item')[0].id);
            });
    }

    initVideoCursor() {
        let cursorImg = this.$element.find('.video-container-manual-play').attr('data-cursor');
        this.$element.find('.video-container-manual-play .video-cursor span').css('background-image', 'url(' + cursorImg + ')');
    }

    initPopup() {
        let _self = this;

        this.$lightboxTrigger.magnificPopup({
            type: 'inline',
            preloader: true,
            fixedContentPos: true,
            removalDelay: 500,
            closeBtnInside: true,
            closeOnBgClick: true,
            enableEscapeKey: true,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = this.st.el.attr('data-effect');
                    $('body').addClass('overflow-hidden');
                },
                beforeClose: function () {
                    $('body').removeClass('overflow-hidden');
                    _self.vimeoPlayers.forEach((player) => {
                        player.destroy();
                    });
                },
                afterClose: function() {
                    history.pushState(null, null, ' ');
                },
                afterChange: function () {
                    setTimeout(() => {
                        $('.mfp-container .video-player[data-vimeo-video-id]').each(function () {
                            var options = {
                                id: $(this).attr('data-vimeo-video-id'),
                                width: $(this).width(),
                                maxheight: $(this).height(),
                                title: false,
                                byline: false,
                                portrait: false,
                                loop: $(this).attr('data-vimeo-video-loop') === 'true',
                                muted: $(this).attr('data-vimeo-video-muted') === 'true',
                                autoplay: $(this).attr('data-vimeo-video-autoplay') === 'true',
                            };

                            if (!options.muted) { options.autoplay = false; }
                            if (options.autoplay && options.muted) {
                                options.controls = $(this).attr('data-vimeo-video-controls') === 'true';
                            } else {
                                options.controls = true;
                            }

                            var player = new Vimeo.Player(this, options);
                            _self.vimeoPlayers.push(player);
                            // don't track background videos
                            if(!options.autoplay || options.controls) {
                                trackVideo(player);
                            }
                        });
                    }, 100);
                },
                change: function() {
                    $('.mfp-container').animate({ scrollTop: 0}, 1000);
                }
            },
            midClick: true
        });

        this.$lightboxTrigger.click((e) => {
            history.pushState(null, null, $(e.target).attr('href'));
        });


        if(window.location.hash && this.$lightboxTrigger.is('[href = "' + window.location.hash + '"]')) {
            this.$lightboxTrigger.filter('[href = "' + window.location.hash + '"]').filter('.crafted-carousel__link').click();
        }
    }
}