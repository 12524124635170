import { gsap } from "gsap";

export default class CraftedCta {
    constructor() {
        this.element = document.querySelector('.js-crafted-cta:not(.is-inited)');

        this.borderTop = this.element.querySelector('.js-cta-border-top');
        this.borderLeft = this.element.querySelector('.js-cta-border-left');
        this.borderRight = this.element.querySelector('.js-cta-border-right');

        this.line = this.element.querySelector('.js-cta-animated-line');
        this.animatedLine = this.element.querySelector('.js-cta-animated-line span');

        this.init();
    }

    init() {
        this.element.classList.add('is-inited');
        this._addAnimations();
    }

    _addAnimations() {
        gsap.to(this.borderTop, {
            height: 0,
            scrollTrigger: {
                trigger: this.element,
                start: "top center",
                end: "top 100px",
                scrub: true,
                once: false
            }
        });
        gsap.to(this.borderLeft, {
            width: 0,
            scrollTrigger: {
                trigger: this.element,
                start: "top center",
                end: "top 100px",
                scrub: true,
                once: false
            }
        });
        gsap.to(this.borderRight, {
            width: 0,
            scrollTrigger: {
                trigger: this.element,
                start: "top center",
                end: "top 100px",
                scrub: true,
                once: false
            }
        });

        gsap.to(this.animatedLine, {
            height: "100%",
            duration: 2,
            scrollTrigger: {
                trigger: this.line,
                start: "top center"
            }
        });
    }
}
