import $ from 'jquery';
import 'src/legacy/js/vendors/foundation/foundation';

export default class CraftedNavigation {
    constructor() {
        this.element = document.querySelector('.js-crafted-navigation');

        this.desktopNav = this.element.querySelector('.js-crafted-navigation-desktop');
        this.mobileNav = this.element.querySelector('.js-crafted-navigation-mobile');

        this.sections = document.querySelectorAll('div[data-crafted-navigation]');

        this.init();
    }

    init() {
        this.sections.forEach( section =>  {
            const sectionTitle = section.getAttribute('data-crafted-navigation');
            const isTimeline = section.classList.contains('js-timeline');

            const desktopNavItem = document.createElement('div');
            desktopNavItem.classList.add('crafted-navigation__desktop-item');
            desktopNavItem.setAttribute('data-section-id', section.id);
            desktopNavItem.setAttribute('data-siteengagement-event', '');
            if(isTimeline) { desktopNavItem.classList.add('is-timeline'); }
            desktopNavItem.innerHTML = '<span>' + sectionTitle + '</span>';
            this.desktopNav.appendChild(desktopNavItem);
            desktopNavItem.addEventListener('click', this.onItemClick.bind(this));

            const mobileNavItem = document.createElement('div');
            mobileNavItem.classList.add('crafted-navigation__mobile-item');
            mobileNavItem.setAttribute('data-section-id', section.id);
            mobileNavItem.setAttribute('data-siteengagement-event', '');
            mobileNavItem.innerHTML = '<span>' + sectionTitle + '</span>';
            this.mobileNav.appendChild(mobileNavItem);
            mobileNavItem.addEventListener('click', this.onItemClick.bind(this));

        });

        this.desktopNav.querySelector('div').classList.add('is-active');
        this.desktopNav.querySelector('div').classList.add('is-current');
        this.mobileNav.querySelector('div').classList.add('is-active');

        this.mobileNav.addEventListener('click', this.onMobileNavClick.bind(this));

        document.addEventListener('scroll',
            Foundation.utils.throttle(() => {
                this.sections.forEach((item) => {
                    if(this._isInViewport(item)) {
                        let sectionId = item.id;
                        if(!this.desktopNav.querySelector('div[data-section-id="' + sectionId + '"').classList.contains('is-current')) {
                            this.desktopNav.querySelector('.is-current').classList.remove('is-current');
                            this.desktopNav.querySelectorAll('.is-active').forEach(item => {
                                item.classList.remove('is-active');
                            });
                            this.mobileNav.querySelector('.is-active').classList.remove('is-active');
                            let desktopCurrentEl = this.desktopNav.querySelector('div[data-section-id="' + sectionId + '"');
                            this._getPreviousSiblings(desktopCurrentEl).forEach(item => {
                                item.classList.add('is-active');
                            });
                            desktopCurrentEl.classList.add('is-active');
                            desktopCurrentEl.classList.add('is-current');
                            this.mobileNav.querySelector('div[data-section-id="' + sectionId + '"').classList.add('is-active');
                        }
                    }
                });
            }, 100).bind(this)
        );
    }

    onItemClick(e) {
        if(e.currentTarget.parentNode.isEqualNode(this.mobileNav) && !this.mobileNav.classList.contains('is-opened')) {
            return;
        }
        const item = e.currentTarget;
        const sectionId = item.getAttribute('data-section-id');
        let topOffset = 0;

        if(item.classList.contains('is-timeline')) {
            this.sections.forEach((section, index) => {
                if (section.id == sectionId && index > 0) {
                    topOffset = $(this.sections[index-1]).offset().top + $(this.sections[index-1]).outerHeight();
                }
            });
        } else {
            topOffset = $('#' + sectionId).offset().top;
        }

        $('html, body').animate({ scrollTop: topOffset }, 1000);
        window.location.hash = '#' + sectionId;
        this.mobileNav.classList.remove('is-opened');
        e.stopPropagation();
    }

    onMobileNavClick(e) {
        if(!e.currentTarget.classList.contains('is-opened')) {
            e.currentTarget.classList.add('is-opened');
        }
    }

    _isInViewport = function (elem) {
        let bounding = elem.getBoundingClientRect();
        let screenHeight = window.innerHeight || document.documentElement.clientHeight;
        return (
            (bounding.top >= 0 && bounding.top <= (screenHeight*0.7))
        );
    }

    _getPreviousSiblings = function(elem, filter) {
        var sibs = [];
        while (elem = elem.previousSibling) {
            if (elem.nodeType === 3) continue; // text node
            if (!filter || filter(elem)) sibs.push(elem);
        }
        return sibs;
    }

}