import $ from 'jquery';

export default class CraftedHeader {
    constructor() {
        this.$element = $('.js-crafted-header');
        this.init();
    }

    init() {
        this.initVideoCursor();
    }

    initVideoCursor() {
        let cursorImg = this.$element.find('.video-container-manual-play').attr('data-cursor');
        this.$element.find('.video-container-manual-play .video-cursor span').css('background-image', 'url(' + cursorImg + ')');
    }
}
