import './scss/crafted.scss';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ProgressBar from '../progress-bar/js/progress-bar.component';
import '../progress-bar/scss/index.scss';

import Timeline from '../timeline/js/timeline.component';
import '../timeline/scss/index.scss';

import CraftedCta from '../crafted-cta/js/crafted-cta.component';
import '../crafted-cta/scss/index.scss';

import CraftedCarouselLine from '../crafted-carousel/js/crafted-carousel-line.component';

gsap.registerPlugin(ScrollTrigger);

//init components related to scroll after register ScrollTrigger plugin

if(document.querySelectorAll('.js-timeline').length) {
    const timelineEl = new Timeline();
}

if(document.querySelectorAll('.js-crafted-cta').length) {
    const craftedCtaEl = new CraftedCta();
}

if(document.querySelectorAll('.js-carousel-animated-line').length) {
    const craftedCarouselLineEl = new CraftedCarouselLine();
}

if(document.querySelectorAll('.js-progress-bar').length) {
    const progressBarEl = new ProgressBar();
}