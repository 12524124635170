import { gsap } from "gsap";

export default class ProgressBar {
    constructor() {
        this.element = document.querySelector('.js-progress-bar:not(.is-inited)');
        this.init();
    }

    init() {
        this.element.classList.add('is-inited');
        this._addAnimations();
    }

    _addAnimations() {
        gsap.to(this.element, {
            value: 100,
            ease: 'none',
            scrollTrigger: {
                scrub: 0.3
            }
        });
    }
}
