import { gsap } from "gsap";

export default class CraftedCarouselLine {
    constructor() {
        this.line = document.querySelector('.js-carousel-animated-line');
        this.animatedLine = document.querySelector('.js-carousel-animated-line span');
        this.init();
    }

    init() {
        this._addAnimations();
    }

    _addAnimations() {
        gsap.to(this.animatedLine, {
            height: "100%",
            duration: 2,
            scrollTrigger: {
                trigger: this.line,
                start: "top center"
            }
        });
    }
}
